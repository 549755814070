(function () {

    document.addEventListener("DOMContentLoaded", function () {

    }, false);
    
    window.addEventListener("load", function () {
        var elements = document.querySelectorAll(".js-loading");

        elements.forEach(function (element) {
            element.classList.remove("js-loading");
        })
            
        
    })

})();

function onClickHandler(e) {

    const href = e.target.getAttribute("href");
  
    e.preventDefault();
    document.querySelector(href).scrollIntoView({
        behavior: "smooth"
    });

    return false;
}



